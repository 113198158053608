import { appNames } from "./lib/appNames";
const inaiaColors = {
  50: "#E6F0FF",
  100: "#CCE0FF",
  200: "#99C2FF",
  300: "#66A3FF",
  400: "#3385FF",
  500: "#0076F2",
  600: "#0066E0",
  700: "#0058C2",
  800: "#0049A3",
  900: "#0065D3", //base primary
};

const gggColors = {
    50:  "#E6F4F0",
    100: "#CCE8E0",
    200: "#99D0C0",
    300: "#66B8A0",
    400: "#339080",
    500: "#0F6A55",
    600: "#0D5F4C",
    700: "#0B5343",
    800: "#09483A",
    900: "#083C2F" //base primary
  }
export const colorPalettes = {
    [appNames.getGreenGold]: {
      primary: gggColors,
      strokeColor: '#198754'
    },
    [appNames.inaiaEu]: {
      primary: inaiaColors,
      strokeColor: '#0065D3'
    },
    [appNames.inaiaUk]: {
      primary: inaiaColors,
      strokeColor: '#0065D3'
    },
};