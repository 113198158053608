import libConfig from "../lib.config";
import { appNames } from "../appNames";
export class StaticLinksService {
    
    private static appName = appNames.inaiaEu
    public static setAppName(name:appNames){
        this.appName = name;
    }
    public static getPrivacyPolicyUrl() : string{
        const app = libConfig.staticLinks[this.appName]
        return app.privacyPolicy;
    }
    public static getImpressumUrl() : string{
        const app = libConfig.staticLinks[this.appName]
        return app.imprint;
    }
}