export enum ConditionTypes {
    registration='registration',
    silverOrder='silver_one_time',
    goldOrder='gold_one_time',
    silverSavingPlan='silver_savings_plan',
    goldSavingPlan='gold_savings_plan',
    depotGold='gold_depot',
    depotSilver='silver_depot',
    platinumOrder='platinum_one_time',
    palladiumOrder='palladium_one_time',
    platinumSavingPlan='platinum_savings_plan',
    palladiumSavingPlan='palladium_savings_plan',
    depotPlatinum='platinum_depot',
    depotPalldium='palladium_depot'
}