<template>
  <div class="flex items-center" :style="cssVariables">
    <span class="loading__anim" :class="className" :style="{width : width + 'px',height: height+'px'}"></span>
  </div>
</template>
<script setup lang="ts">
import { useColors } from '@/hooks/useColors';
const { colors } = useColors();
const cssVariables = {
  '--dynamic-color': colors.strokeColor,
};
const props = defineProps({
    height:{
             type:Number,
             default:35
         },
         width:{
             type:Number,
             default:35
         },
         className: {
            type: String,
            default: ""
         }
})
</script>
<style scoped>
    @keyframes rotate { to {  transform: rotate(1turn) }}
    .loading__anim {
        border-radius:50%;
        display: inline-block;
        border: 2px solid rgba(189,189,189 ,0.25);
        border-left-color: var(--dynamic-color);
        border-top-color: var(--dynamic-color);
        animation: rotate 600ms infinite linear;
        }
</style>
