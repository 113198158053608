export enum OrderTypes{
    gold_purchase='gold_purchase',
    gold_sale='gold_sale',
    gold_sell='gold_sell',
    gold_withdrawal='gold_withdrawal',
    gold_delivery='gold_delivery',
    gold_gift='gold_gift',
    gold_purchase_interval='gold_purchase_interval',
    gold_transfer_in='gold_transfer_in',
    gold_transfer_out='gold_transfer_out',
    PAYMENT_IN='PAYMENT_IN',
    PAYMENT_OUT='PAYMENT_OUT',
    FUND_TRANSFER='FUND_TRANSFER',
    WITHDRAWAL='WITHDRAWAL',
    REDEMPTION='REDEMPTION',
    gold_refund='gold_refund',
    silver_delivery='silver_delivery',
    silver_sell='silver_sell',
    silver_purchase='silver_purchase',
    silver_withdrawal='silver_withdrawal',
    silver_gift='silver_gift',
    silver_purchase_interval='silver_purchase_interval',
    silver_transfer_in='silver_transfer_in',
    silver_transfer_out='silver_transfer_out',
    silver_refund='silver_refund',
    platinum_delivery='platinum_delivery',
    platinum_sell='platinum_sell',
    platinum_purchase='platinum_purchase',
    platinum_withdrawal='platinum_withdrawal',
    platinum_gift='platinum_gift',
    platinum_purchase_interval='platinum_purchase_interval',
    platinum_transfer_in='platinum_transfer_in',
    platinum_transfer_out='platinum_transfer_out',
    platinum_refund='platinum_refund',

    palladium_delivery='palladium_delivery',
    palladium_sell='palladium_sell',
    palladium_purchase='palladium_purchase',
    palladium_withdrawal='palladium_withdrawal',
    palladium_gift='palladium_gift',
    palladium_purchase_interval='palladium_purchase_interval',
    palladium_transfer_in='palladium_transfer_in',
    palladium_transfer_out='palladium_transfer_out',
    palladium_refund='palladium_refund',
}